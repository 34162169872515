@page {
    size: A4;
    margin: 0.5cm;
}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;
    }
}
